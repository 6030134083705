// Color Variables
$white: #ffffff;
$black: #000000;

$primary: #213469;
$secondary: #ee4623;
$theme-blue: #213469;
$theme-yellow: #ffdb19;
$theme-cyan: #4ad5e4;
$theme-red: #ee4623;

/*--------------mixins------------------*/
@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin border-radius($radius: none) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin appearance() {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@function calculateRem($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}

@mixin fontSize($size) {
    //font-size: $size;
    font-size: calculateRem($size);
}

@mixin position($position,
    $top: null,
    $right: null,
    $bottom: null,
    $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}