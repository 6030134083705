@import "../../../CommonSCSS/mixins.scss";

.desktopFooter {
  h6 {
    font-family: "Alegreya Sans", sans-serif;
    color: $theme-blue;
    font-weight: 500;
    margin-bottom: 4px;
  }
  p,
  a {
    color: $theme-blue;
    font-family: "Alegreya Sans", sans-serif;
    @include fontSize(14px);
    line-height: 20px;
    font-weight: 500;
    span {
      font-weight: 600;
    }
  }
  .disclaimer {
    margin-bottom: 12px;
    color: $black;
    font-weight: 400;
  }
  ul {
    li {
      margin-bottom: 12px;
      a {
        font-weight: 600;
        padding: 4px 0px;
        display: inline-block;
      }
    }
  }
}
