@import "../../../../CommonSCSS/mixins.scss";

.checkbox {
  // width: 100%;
  align-items: flex-start !important;
  margin-right: 0px !important;
  display: inline-flex !important;

  .MuiTypography-root {
    @include fontSize(14px);
    font-weight: 400;
    line-height: 18px;
    font-family: "Poppins", sans-serif;
    text-align: left;
    color: $black;
  }

  .MuiCheckbox-root {
    padding: 0px 10px 0px 12px;
  }

  .checkIcon {
    position: relative;

    &:before {
      @include position(absolute, $top: 2px, $left: 5px);
      content: "";
      display: block;
      width: 4px;
      height: 8px;
      border: solid #fff;
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg);
    }
  }
}
