@import "../../../CommonSCSS/mixins.scss";

.navLinks {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 32px;
  a {
    color: $black;
    @include fontSize(16px);
    padding: 8px 4px;
    font-family: "Alegreya Sans", sans-serif;
    font-weight: 700;
    display: inline-block;
    &:hover {
      color: $theme-red;
    }
  }
  button {
    color: $black;
    @include fontSize(16px);
    padding: 4px;
    font-family: "Alegreya Sans", sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 12px;
    &:hover {
      background: transparent;
      color: $theme-red;
    }
    svg {
      color: $theme-red;
    }
  }
}

.MuiList-root li {
  @include fontSize(14px);
  justify-content: end;
}

.modalPopup {
  .MuiPaper-root {
    border-radius: 20px;
    @media(max-width:499px){
      margin: 12px;
    }
    .close {
      color: $black;
      border: 1px solid $black;
      border-radius: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      &:hover {
        background: #555;
        svg {
          color: $white;
        }
      }
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
