@import "./../../../CommonSCSS/mixins.scss";

.mhomepage {
    padding-top: 80px;

    h3 {
        color: $primary;
    }

    .products-section {
        margin-bottom: 100px;

        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            justify-content: center;

            li {
                border-radius: 20px;
                background: rgba($theme-red, 0.1);
                cursor: pointer;
                position: relative;
                max-width: calc(50% - 12px);

                &.disabled {
                    cursor: wait;
                    display: block;
                    opacity: 0.6;

                    &:after {
                        background: url("./../../../../public/images/coming-soon2.svg") no-repeat center/100px;
                        content: '';
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                    }
                }

                &:hover {
                    box-shadow: 0px 0px 10px rgba($black, 0.3);
                }

                &:nth-child(2),
                &:nth-child(5) {
                    background: rgba($theme-blue, 0.1);
                }

                p {
                    font-weight: 600;
                    color: $theme-blue;
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    @include fontSize(16px);
                    line-height: 20px;
                    font-family: 'Alegreya Sans', 'sans-serif';

                    span {
                        color: $theme-red;
                    }
                }

                img {
                    width: 100%;

                }
            }
        }
    }

    .whyBesecured_box {
        margin-bottom: 100px;

        h3 {
            margin-bottom: 15px;
            color: $theme-blue;
        }

        p {
            margin-bottom: 12px;
            line-height: 30px;
            color: $theme-blue;
            text-align: justify;
        }

        .whyList_m {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            li {
                @include fontSize(16px);
                color: $theme-blue;
                font-weight: 400;
                line-height: 30px;
                position: relative;
                padding-left: 20px;
                font-family: 'poppins';

                &:before {
                    background: $theme-red;
                    border-radius: 20px;
                    content: '';
                    position: absolute;
                    left: 0px;
                    top: 10px;
                    height: 10px;
                    width: 10px;
                }

                span {
                    color: $theme-red;
                    @include fontSize(18px);
                    font-weight: 600;
                }
            }
        }
    }

    .knwMore_section {
        margin-bottom: 100px;

        .prod-sec {
            max-width: 384px;
            margin: auto;
            min-height: 932px;
            position: relative;
            background: url("./../../../../public/images/prod-line-curve.svg")no-repeat center center/85%;

            .prod-list {
                li {
                    margin-bottom: 5px;


                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:nth-child(even) {
                        text-align: right;
                    }

                    &:nth-child(2) {
                        a {
                            position: relative;
                            right: 50px;
                        }
                    }

                    a {
                        display: inline-block;

                        &.notAllowed {
                            cursor: not-allowed;
                            opacity: 0.5;
                        }

                        img {
                            border-radius: 50%;
                            width: 108px;
                            height: 108px;
                        }

                        p {
                            padding-top: 5px;
                            color: $theme-red;
                            font-weight: 700;
                            font-family: 'Alegreya Sans', 'sans-serif';
                            font-size: 16px;
                            line-height: 20px;
                            text-align: center;
                            // background: $white;

                            span {
                                display: block;

                                color: $theme-blue;
                            }
                        }
                    }
                }
            }
        }
    }

    .advantage_sec {
        margin-bottom: 100px;

        .advantageList_m {
            border-radius: 24px;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            margin-top: 24px;

            li {
                background: rgba($theme-red, 0.1);
                width: 50%;
                padding: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-height: 200px;

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(6) {
                    background: #E6EDED;
                }

                img {
                    width: 100%;
                    max-width: 106px;
                }

                h4 {
                    color: $theme-blue;
                    margin-bottom: 12px;
                    font-size: 16px;
                    line-height: 24px;
                }

                p {
                    color: $theme-blue;
                    line-height: 24px;
                    font-size: 12px;
                }
            }
        }
    }

    .faqs-section {
        margin-bottom: 100px;

        .MuiPaper-root {
            box-shadow: none;

            .MuiSvgIcon-root {
                color: $theme-red;
                height: 30px;
                width: 30px;
            }
        }

        .MuiAccordionSummary-root {
            flex-direction: row-reverse;
            gap: 16px;
            padding: 0;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            &.Mui-expanded {
                min-height: 48px;
            }
        }

        .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
            transform: rotate(0deg);
        }

        .MuiAccordionSummary-content {
            @include fontSize(18px);
            font-weight: 700;
            color: $theme-blue;
            font-family: "Alegreya Sans", sans-serif;
            margin: 0px;
            line-height: 28px
        }

        .MuiAccordionDetails-root {
            @include fontSize(14px);
            line-height: 20px;
            color: $theme-blue;
            font-family: "Poppins";
        }
    }
}