@import "../../CommonSCSS/mixins.scss";

.ctaBtn {
  %primary {
    background: $secondary;
    color: $white;
    @include border-radius(8px);
    font-family: "Alegreya Sans", sans-serif;
    text-transform: capitalize;
    font-weight: 600;
    @include box-shadow(0px 0px 0px rgba($white, 0));
    &:hover {
      background: lighten($primary, 8%);
      @include box-shadow(0px 0px 0px rgba($white, 0));
    }
  }

  // Primary Buttons
  .regularPrimaryBtn {
    @extend %primary;
    @include fontSize(20px);
    line-height: 24px;
    padding: 12px 20px;
  }
  .mediumPrimaryBtn {
    @extend %primary;
    @include fontSize(16px);
    line-height: 16px;
    padding: 12px 20px;
  }
  .smallPrimaryBtn {
    @extend %primary;
    @include fontSize(12px);
    padding: 4px 8px;
  }
}
