@import "./mixins.scss";

.productList {
    padding-top: 120px;

    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        li {
            border-radius: 20px;
            background: rgba($theme-red, 0.1);
            cursor: pointer;
            position: relative;
            flex: 1 0 0;
            max-width: calc(33% - 10px);
            min-width: 30%;

            &.disabled {
                cursor: wait;
                display: block;
                opacity: 0.6;

                &:after {
                    background: url("../../public/images/coming-soon2.svg") no-repeat center/160px;
                    content: '';
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
            }

            &:hover {
                box-shadow: 0px 0px 10px rgba($black, 0.3);
            }

            &:nth-child(2),
            &:nth-child(5) {
                background: rgba($theme-blue, 0.1);
            }

            p {
                font-weight: 600;
                color: $theme-blue;
                position: absolute;
                top: 16px;
                left: 16px;
                font-family: 'Alegreya Sans', 'sans-serif';

                span {
                    color: $theme-red;
                }
            }

            img {
                width: 100%;

            }
        }
    }
}

.whyList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    li {
        width: calc(50% - 20px);
        @include fontSize(16px);
        color: $theme-blue;
        font-weight: 700;
        line-height: 20px;
        position: relative;
        padding-left: 24px;

        &:before {
            background: $theme-red;
            border-radius: 20px;
            content: '';
            position: absolute;
            left: 0px;
            top: 5px;
            height: 10px;
            width: 10px;
        }

        span {
            color: $theme-red;
            @include fontSize(18px);
        }
    }
}

.advantageList {
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    li {
        background: rgba($theme-red, 0.1);
        width: 33.33%;
        padding: 60px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:nth-child(even) {
            background: #E6EDED;
        }

        img {
            width: 100%;
            max-width: 200px;
        }

        h4 {
            color: $theme-blue;
            margin-bottom: 12px;
        }

        p {
            color: $theme-blue;
            line-height: 28px;
            margin-top: 4px;
            margin-bottom: 4px;
        }
    }
}

.knowMore {
    position: relative;
    margin-bottom: 120px;

    &:before {
        position: absolute;
        background: url("../../public/images/knowmore_bg.svg") no-repeat 0px 0px/86%;
        content: "";
        width: 100%;
        height: 463px;
        top: 150px;
        left: 50px;
    }

    .list1 {
        display: flex;
        position: relative;
        margin-bottom: 230px;

        li {
            .MuiTypography-root {
                color: $theme-red;
                display: inline-block;

                &:hover {
                    color: $theme-blue;
                }

                img {
                    border-radius: 50%;
                }

                p {
                    font-weight: 600;
                    margin-top: 8px;
                }
            }

            &:nth-child(1) {
                margin-right: 130px;
            }

            &:nth-child(2) {
                position: relative;
                top: 46px;
                margin-right: 132px;
            }

            &:nth-child(3) {
                position: absolute;
                top: 210px;
                right: 278px;
                margin-right: 132px;
            }
        }
    }

    .list2 {
        display: flex;
        position: relative;

        li {
            .notAllowed {
                cursor: not-allowed;
                opacity: 0.5;
            }

            .MuiTypography-root {
                color: $theme-red;
                display: inline-block;

                &:hover {
                    color: $theme-blue;
                }

                img {
                    border-radius: 50%;
                }

                p {
                    font-weight: 600;
                    margin-top: 8px;
                }
            }

            &:nth-child(1) {
                margin-left: 306px;
            }

            &:nth-child(2) {
                margin-left: 235px;
                position: relative;
                top: 5px;
            }

            &:nth-child(3) {
                position: absolute;
                bottom: 125px;
                right: 0;
            }
        }
    }
}

.faqs {
    padding: 100px 0px;

    .MuiPaper-root {
        box-shadow: none;

        .MuiSvgIcon-root {
            color: $theme-red;
            height: 30px;
            width: 30px;
        }
    }

    .MuiAccordionSummary-root {
        flex-direction: row-reverse;
        gap: 16px;

        &.Mui-expanded {
            min-height: 48px;
        }
    }

    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: rotate(0deg);
    }

    .MuiAccordionSummary-content {
        @include fontSize(20px);
        font-weight: 700;
        color: $theme-blue;
        font-family: "Alegreya Sans", sans-serif;
        margin: 0px;
    }

    .MuiAccordionDetails-root {
        @include fontSize(16px);
        color: $theme-blue;
        font-family: "Poppins";
    }
}

// CMS
.contentList {
    margin-top: 24px;

    li {
        @include fontSize(16px);
        font-family: "Poppins";
        position: relative;
        padding-left: 20px;
        margin-bottom: 12px;

        &:before {
            border-radius: 20px;
            background: $theme-red;
            position: absolute;
            left: 0px;
            top: 7px;
            content: '';
            height: 10px;
            width: 10px;
        }
    }
}

.claimQuestion {
    .MuiPaper-root {
        box-shadow: none;
        background: rgba(33, 52, 105, 0.08);
        border-radius: 12px !important;
        margin-bottom: 12px;

        &:before {
            display: none;
        }

        .MuiTypography-root {
            font-weight: 500;
            @include fontSize(16px);
            color: $theme-blue;
            font-family: 'Poppins';
        }

        ul {
            li {
                position: relative;
                padding-left: 16px;
                @include fontSize(16px);
                font-weight: 400;
                line-height: 28px;
                font-family: "Poppins";
                margin-top: 4px;
                margin-bottom: 4px;

                &:before {
                    border-radius: 20px;
                    background: $theme-red;
                    content: '';
                    height: 8px;
                    width: 8px;
                    position: absolute;
                    left: 0px;
                    top: 10px;
                }
            }
        }
    }
}

.cmsPages {
    padding-top: 140px;
    padding-bottom: 100px;

    p,
    ul li {
        @include fontSize(14px);
        font-family: "Poppins";
        line-height: 22px;
        margin-bottom: 8px;
    }

    li {
        list-style-type: disc;
        margin-left: 24px;
    }

    h5 {
        font-weight: 600;
        margin-top: 16px;
        margin-bottom: 8px;
    }


}

.upload_section {
    @include border-radius(10px);
    background: $white;
    padding: 15px;
    z-index: 9;
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;

    svg {
        height: 24px;
        color: $secondary
    }

    span {
        @include fontSize(24px);
        line-height: 24px;
        font-weight: 600;
        margin-left: 12px;
        font-family: "Alegreya Sans";
        color: $secondary;
    }

    .fileUpload {
        .custom-file-upload {
            display: inline-block;
            color: #333333;
            cursor: pointer;

            span {
                margin-right: 10px;
                display: inline-block;
            }

            input {
                display: none;
            }
        }
    }
}

.productHeadImg {
    max-width: 460px;
    margin-top: 140px;

    @media (max-width:800px) {
        margin-top: 24px;
    }
}

.productHeading {
    margin-top: 40px;
    @include fontSize(58px);
    line-height: 48px;

    @media (max-width:800px) {
        margin-top: 0px;
        @include fontSize(40px);
    }
}

.productPages {
    padding-bottom: 100px;

    h5 {
        font-weight: 600;
        margin-top: 16px;
        margin-bottom: 8px;

        @media (max-width:800px) {
            @include fontSize(16px);
            line-height: 22px;
        }
    }

    h4 {
        margin-bottom: 12px;
        color: $theme-blue;
        @include fontSize(28px);

        @media (max-width:800px) {
            @include fontSize(24px);
            line-height: 30px;
        }
    }

    p {
        margin-bottom: 12px;

        @media (max-width:800px) {
            @include fontSize(14px);
            line-height: 22px;
            text-align: justify;
        }
    }

    ul {
        margin-bottom: 20px;

        li {
            @include fontSize(16px);
            font-family: "Poppins";
            position: relative;
            padding-left: 20px;
            margin-bottom: 12px;

            @media (max-width:800px) {
                @include fontSize(14px);
                line-height: 22px;
                padding-left: 14px;
                margin-bottom: 4px;
            }

            &:before {
                border-radius: 20px;
                background: $theme-red;
                position: absolute;
                left: 0px;
                top: 7px;
                content: '';
                height: 10px;
                width: 10px;

                @media (max-width:800px) {
                    top: 8px;
                    height: 6px;
                    width: 6px;
                }
            }
        }
    }
}