@import "./mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@300;400;500;700&family=Poppins:wght@400;500;600;700&display=swap');

h1, h2, h3, h4 {
    font-family: 'Alegreya Sans', sans-serif;
}

h1 {
    @include fontSize(52px);
    line-height: 64px;
    font-weight: 700;
}

h2 {
    @include fontSize(50px);
    font-weight: 700;
    line-height: 60px;
}

h3 {
    @include fontSize(40px);
    font-weight: 700;
    line-height: 46px;
    @media(max-width:699px){
        @include fontSize(28px);
            line-height: 34px;
    }
}

h4 {
    @include fontSize(24px);
    font-weight: 600;
    line-height: 36px;
}

h5 {
    @include fontSize(18px);
    font-weight: 400;
    line-height: 24px;
    font-family: "Poppins";
}

h6 {
    @include fontSize(16px);
    font-weight: 500;
    line-height: 24px;
}

p {
    @include fontSize(16px);
    font-weight: 400;
    line-height: 28px;
    font-family: "Poppins";
}

.mView {

    h1 {
        @include fontSize(50px);
        line-height: 75px;
        font-weight: 600;
    }

    h2 {
        @include fontSize(40px);
        font-weight: 600;
        line-height: 60px;
    }

    h3 {
        @include fontSize(32px);
        font-weight: 600;
        line-height: 42px;
    }

    h4 {
        @include fontSize(16px);
        font-weight: 600;
        line-height: 20px;
    }

    h5 {
        @include fontSize(14px);
        font-weight: 600;
        line-height: 20px;
    }

    h6 {
        @include fontSize(12px);
        font-weight: 600;
        line-height: 18px;
    }

    p {
        @include fontSize(10px);
        font-weight: 400;
        line-height: 16px;
    }
}