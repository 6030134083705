@import "../../../CommonSCSS/mixins.scss";

.MuiList-root {
    padding: 12px;
    a {
        color: $theme-blue;
        display: block;
        @include fontSize(16px);
        font-weight: 500;
        text-align: right;
        padding: 4px 0px;
        margin: 8px 0px;
    }
    
}

.MuiDrawer-modal{
    z-index: 99 !important;
}