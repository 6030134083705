@import "./CommonSCSS/FontStyle.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -moz-osx-font-smoothing: grayscale;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Alegreya Sans', sans-serif;
  font-style: normal;
  font-size: 16px;
}

a {
  cursor: pointer;
  text-decoration: none !important;
}

img {
  vertical-align: middle;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

.MuiPickersToolbar-content {
  display: none !important;
}

.MuiGrid2-container {
  max-width: 1200px;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.row {
  max-width: 1224px;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}

// Margins
@for $i from 0 to 40 {
  .ml-#{$i} {
    margin-left: 4px * $i !important;
  }

  .mr-#{$i} {
    margin-right: 4px * $i !important;
  }

  .mt-#{$i} {
    margin-top: 4px * $i !important;
  }

  .mb-#{$i} {
    margin-bottom: 4px * $i !important;
  }

  .mx-#{$i} {
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }

  .my-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
  }

  .m-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }

  .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

// Paddings
@for $i from 0 to 40 {
  .pl-#{$i} {
    padding-left: 4px * $i !important;
  }

  .pr-#{$i} {
    padding-right: 4px * $i !important;
  }

  .pt-#{$i} {
    padding-top: 4px * $i !important;
  }

  .pb-#{$i} {
    padding-bottom: 4px * $i !important;
  }

  .px-#{$i} {
    padding-left: 4px * $i !important;
    padding-right: 4px * $i !important;
  }

  .py-#{$i} {
    padding-top: 4px * $i !important;
    padding-bottom: 4px * $i !important;
  }

  .p-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }
}